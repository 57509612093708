<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <!-- <list-tool-bar>
                    <el-button type="primary"  style="margin-bottom:15px;" v-if="auth.eidtEmp" @click.stop="handleEditEmpDia">批量修改对接员工</el-button>
                </list-tool-bar> -->
                <el-row  style="flex-wrap: wrap;padding:16px 16px 0 16px;background:#F4F7FA; margin-bottom:15px;" class="flex">
                    <el-col :span="6" style="margin-bottom:16px;">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select style="width:120px;" v-model="search_type" slot="prepend" placeholder="请选择">
                                    <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :span="6" style="margin-left:24px;margin-bottom:16px;">
                        <div style="display:flex;align-items: center;">
                            <div style="flex-shrink: 0;">协议名称：</div>
                             <el-select v-model="search_name" style="flex:1;">
                                <el-option
                                    v-for="e in treatyList"
                                    :key="e.id"
                                    :label="e.name"
                                    :value="e.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-button style="margin-left:24px;margin-bottom:16px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                </el-row>
                <el-table :data="tableData" @selection-change="handleSelectionChange">
                     <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
                    <el-table-column label="渠道编号" prop="chanCode"></el-table-column>
                    <el-table-column label="渠道区域" prop="regionName"></el-table-column>
                    <el-table-column label="渠道名称" prop="chanName" min-width="160"></el-table-column>
                    <el-table-column label="类型" prop="typeName"></el-table-column>
                    <el-table-column label="联系电话" prop="phone"></el-table-column>


                    <el-table-column label="渠道协议名称"  prop="threatyName"></el-table-column>
                    <el-table-column label="协议生效时间"  prop="startTime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.startTime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="协议到期时间"  prop="endTime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.endTime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="协议签约员工"  prop="signEmpName">
                    </el-table-column>
                    <el-table-column label="公司是否已盖章"  prop="stampStateName">
                    </el-table-column>

                    <el-table-column label="操作"
                        fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >回传协议</el-button>
                                <!-- <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button> -->
                            </div>
                        </template>
                    </el-table-column>

                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>

        <!-- 批量修改对接员工 -->
        <el-dialog class="editEmpDia"
        :visible="showEditEmpDia"
        @close="cancelEditEmpDiaTop"
        title="修改渠道商对接员工"
        width="560px">
         <el-form 
                :model="editEmpInfo"
                :rules="editEmpInforules"
                label-width="140px"
                label-suffix=":"
                ref="editEmpInfo"
                style="width:100%;"
                @submit.prevent="seveEditEmpDia"
                >
                <el-form-item label="员工存在系统" prop="sys">
                    <el-radio-group v-model="editEmpInfo.sys">
                        <el-radio label="CRM">房产CRM</el-radio>
                        <el-radio label="AYM">移民CRM</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="对接员工" prop="empid">
                    <template>
                        <el-select class='empidselect' v-model="editEmpInfo.empid" filterable remote reserve-keyword placeholder="请输入" :remote-method="remoteMethod" :loading="loading">
                            <el-option v-for="item in empOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                
     

            <!-- <el-form-item label="绩效工资核算月份" prop="grantTime1" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:380px;" v-model.trim="allGrant.grantTime1" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item>
            <el-form-item label="绩效工资发放月份" prop="grantTime2" style="margin-bottom:24px;">
                    <el-date-picker  :type="datetype"  style="width:380px;" v-model.trim="allGrant.grantTime2" :value-format="valueformat" placeholder="请选择"></el-date-picker>
            </el-form-item> -->
         </el-form>
            <div slot="footer">
                <el-button @click="cancelEditEmpDia" >取消</el-button>
                <el-button @click="seveEditEmpDia"  type="primary">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog class="contract-edit"
        :visible="viewIsReady"
        @close="handleClose"
        title="回传协议信息"
        width="660px">

      

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="170px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="渠道商名称" >
                {{clickRow.chanName}}（{{clickRow.chanCode}}）
            </el-form-item>
            <el-form-item label="公司营业执照" prop="imgPath" v-if="clickRow.type==2" class="filefrom">
                <div v-if="pdfList" class='flex'>
                    <div  @click="handfile(item)"  class="imgItem" v-for="(item,index) in pdfList" :key="index">
                        <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png" >
                        <i class="el-icon-delete del" @click.stop="delBackFile(index)"></i>
                    </div>
                </div>
                <viewer :images="fileList">
                    <div class="flex">
                        <div class="imgItem" v-for="(item,index) in fileList" :key="index">
                            <img class="inlineblock img c-pointer " :src="item.url">
                            <i class="el-icon-delete del" @click="delBackPic(index)"></i>
                        </div>
                    </div>
                </viewer>
                <el-upload :data='needzip' v-show="(fileList.length+pdfList.length)<=1" class="avatar-uploader" :action="picUrl" :show-file-list="false" accept=".jpg,.jpeg,.png,.pdf" :on-success="handleBackImgSuccess" :before-upload="handleBeforeUpload" :on-progress="handleProgress">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="el-upload__tip">附件类型：图片或pdf,大小小于5M，最多只能上传2个附件</div>

            </el-form-item>
            <!-- <el-form-item label="协议类型" >
                {{clickRow.typeName}}
            </el-form-item> -->
             <el-form-item label="协议名称" >
                {{clickRow.threatyName}}
            </el-form-item>
            
            <el-form-item label="协议有效期" >
                {{clickRow.beginAfterTime}}
            </el-form-item>
            <el-form-item v-if="clickRow.bankName==''||clickRow.accountName==''||clickRow.bankNum==''" label="开户支行名" prop="bankName" class="clomunform">
                <el-input v-model.trim="PB.bankName" class="width3" maxlength="50"></el-input>
                <div style="font-size:12px;height: 21px;line-height: 33px;padding-left: 15px;">XX银行XX市XX支行/XX分行</div>
            </el-form-item>
            <el-form-item v-if="clickRow.bankName==''||clickRow.accountName==''||clickRow.bankNum==''" label="账户名称" prop="bankAccount">
                <el-input v-model.trim="PB.bankAccount" class="width3" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item v-if="clickRow.bankName==''||clickRow.accountName==''||clickRow.bankNum==''" label="账户号码" prop="bankNum">
                <el-input v-model.trim="PB.bankNum" type="number" class="width3" oninput="if(value.length>20)value=value.slice(0,20)"></el-input>
            </el-form-item>

           

            <el-form-item label="回传渠道商已签署协议" prop="imgPath2" class="filefrom">
                <div v-if="pdfList2" class='flex'>
                    <div  @click="handfile(item)"  class="imgItem" v-for="(item,index) in pdfList2" :key="index">
                        <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png" >
                        <i class="el-icon-delete del" @click.stop="delBackFile2(index)"></i>
                    </div>
                </div>
                <viewer :images="fileList2">
                    <div class="flex">
                        <div class="imgItem" v-for="(item,index) in fileList2" :key="index">
                            <img class="inlineblock img c-pointer " :src="item.url">
                            <i class="el-icon-delete del" @click="delBackPic2(index)"></i>
                        </div>
                    </div>
                </viewer>
                <el-upload :data='needzip' v-show="(fileList2.length+pdfList2.length)<=0" class="avatar-uploader" :action="picUrl" :show-file-list="false" accept=".pdf" :on-success="handleBackImgSuccess2" :before-upload="handleBeforeUpload2" :on-progress="handleProgress2">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div class="el-upload__tip">仅支持上传一个小于10M的pdf文件</div>

            </el-form-item>

            
        </el-form>
        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>


    </div>
</template>

<script>
import { base64encode, utf16to8, utf8to16, base64decode } from "../../utils/base64";

export default {
    components: {},
    props: {},
    data() {
        return {
            clickRow:'',
            viewIsReady: false,
             PB: {
                id:'',
                imgPath:'',
                imgPath2:'',
                bankName:'',
                bankAccount:'',
                bankNum:'',
            },
            posting:false,
             fileList:[],
            pdfList:[],
            picUrl:this.$baseURL + "/api/common/fileSingleUpLoad",
            // 验证规则
            rules: {
                imgPath      : { required: true, message: "请上传公司营业执照",   trigger: ["change","blur"] },
                 imgPath2      : { required: true, message: "请上传渠道商已签署协议",   trigger: ["change","blur"] },
                bankName      : { required: true, message: "请输入开户支行名",   trigger: ["change","blur"] },
                bankAccount      : { required: true, message: "请输入账户名称",   trigger: ["change","blur"] },
                bankNum      : { required: true, message: "请输入账户号码",   trigger: ["change","blur"] },
            },  

             posting2:false,
             fileList2:[],
            pdfList2:[],

            myloadingRef:'',


            treatyList:[],

            tableData: [],
            search_value: "",
            search_name:'',
            search_type: 1,
            pageNum: 1,
            pageSize: 10,
            total: 0,

            searchTypeEnum: [
                { name: "渠道名称", value: 1 },
                { name: "渠道电话", value: 2 },
                { name: "协议签约员工", value: 3 },
            ],
            auth:{
               delete:false,
               eidtEmp:false, 
            },
            showEditEmpDia:false,
            editEmpInfo:{
                sys:'',
                empid:'',
            },
            editEmpInforules:{
                sys      : { required: true, message: "请选择员工存在系统",trigger:['change', 'blur'] },
                //  empid      : { required: true, message: "请选择对接员工",trigger:['change', 'blur'] }
            },
            empOptions:[],
            multipleSelection: "",
            loading: false,
            needzip:{
                isZip:1
            },
            urlQuery: "",
            sysCode: "", //AYM CEN

        };
    },
    created() {
        this.auth.delete = this.$hasAuthFor("api/channel/deleteChannel"); //删除
        this.auth.eidtEmp = this.$hasAuthFor("api/common/channel/updateChannelConnEmp"); //批量修改对接员工

         this.urlQuery = this.getUrlQuery();
        this.sysCode = this.urlQuery.sysCode;

        this.fetchTreatyList()
        this.fetchData();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        getUrlQuery() {
            // 本地调试注意域名要一样

            // let url =
            //     "http://192.168.110.136:8020/#/customer/channelPhoneList/add?id=430&chanCode=CH-AYM242&sysCode=AYM&editPhoneFlag=1&showPhoneFlag=0";
            let url = window.location.href;
            let urlStrDe = url.split("?")[1];
            let urlStr = base64decode(urlStrDe);
            // 创建空对象存储参数
            let obj = {};
            // 再通过 & 将每一个参数单独分割出来
            let paramsArr = urlStr.split("&");
            for (let i = 0, len = paramsArr.length; i < len; i++) {
                // 再通过 = 将每一个参数分割为 key:value 的形式
                let arr = paramsArr[i].split("=");
                obj[arr[0]] = arr[1];
            }
            return obj;
        },
        handleProgress(e, file) {
            this.posting = true;
            //  this.myloadingRef = this.$loading({
            //     target: ".contract-edit .el-dialog"
            // });
        },
        handleProgress2(e, file) {
            this.posting2 = true;
            //  this.myloadingRef = this.$loading({
            //     target: ".contract-edit .el-dialog"  
            // });
            // this.myloadingRef = this.$loading({
            //     target: ".el-loading-mask .el-dialog"  
            // });
            
        },
        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            const suffix4 = img === 'pdf'
            const isLt1M = file.size / 1024 / 1024 < 5;
            if (!suffix && !suffix2 && !suffix3 && !suffix4) {
                this.$message.error("只能上传图片或pdf附件！");
                return false
            }
            // 可以限制图片的大小
            if (!isLt1M) {
                this.$message.error('上传文件大小不能超过 5MB!');
                return false
            }
            return suffix || suffix2 || suffix3 || suffix4
        },
        handleBeforeUpload2(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix4 = img === 'pdf'
            console.log(file.size / 1024 / 1024);
            const isLt1M = file.size / 1024 / 1024 < 10;
            if (!suffix4) {
                this.$message.error("只能上传pdf附件！");
                return false
            }
            // 可以限制图片的大小
            if (!isLt1M) {
                this.$message.error('上传文件大小不能超过 10MB!');
                return false
            }
            return suffix4
        },
        // 删除图片
        delBackPic(index) {
            this.fileList.splice(index, 1);
        },
        // 删除图片
        delBackPic2(index) {
            this.fileList2.splice(index, 1);
        },
        // 删除图片
        delBackFile(index) {
            this.pdfList.splice(index, 1);
        },
        // 删除图片
        delBackFile2(index) {
            this.pdfList2.splice(index, 1);
        },
         handfile(item){
            window.open(item.url)
        },
        // 上传文件成功
        handleBackImgSuccess(res, file) {
            this.posting = false;
            // console.log(this.myloadingRef);
            

            // setTimeout(() => {
            //     this.myloadingRef.close();
            //     console.log(this.myloadingRef);

            // }, 500);
            // this.myloadingRef.close()
            // this.myloadingRef=''
            console.log(file);
            const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
            if(suffix=='pdf'){
                const index = this.pdfList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.pdfList.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath
                    })
                    console.log(this.pdfList);

                    let _imgPath = []
                    this.pdfList.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.PB.imgPath = _imgPath

                } else {
                    this.pdfList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error"
                    })
                }

            }else{
                const index = this.fileList.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.fileList.push({
                         name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath
                    })
                    console.log(this.fileList);
    
                    let _imgPath = []
                    this.fileList.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.PB.imgPath = _imgPath
    
                } else {
                    this.fileList.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error"
                    })
                }
            }


        },
        // 上传文件成功
        handleBackImgSuccess2(res, file) {
            this.posting2 = false;
            // console.log(this.myloadingRef);

            // this.$nextTick().then(() => {
            //     this.myloadingRef.close();
            //     console.log(this.myloadingRef);
            // });



            // this.myloadingRef.close()
            // this.myloadingRef=''
            console.log(file);
            const suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
            if(suffix=='pdf'){
                const index = this.pdfList2.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.pdfList2.push({
                        name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath
                    })
                    console.log(this.pdfList2);

                    let _imgPath = []
                    this.pdfList2.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.PB.imgPath2 = _imgPath

                } else {
                    this.pdfList2.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error"
                    })
                }

            }else{
                const index = this.fileList2.indexOf(file);
                if (res.code === 2000) {
                    const { oldName, filePath } = res.data.files;
                    file.url = this.$baseURL + "/" + filePath;
                    file.path = filePath;
                    this.fileList2.push({
                         name: oldName,
                        url: this.$baseURL + "/" + filePath,
                        path: filePath
                    })
                    console.log(this.fileList2);
    
                    let _imgPath = []
                    this.fileList2.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.PB.imgPath2 = _imgPath
    
                } else {
                    this.fileList2.splice(index, 1);
                    this.$message({
                        message: res.msg,
                        type: "error"
                    })
                }
            }


        },
        async handleSave () {
             if (this.posting||this.posting2) return this.$message({
                message: "请等待上传完成",
                type: "warning"
            });
            const loadingRef = this.$loading({
                target: ".contract-edit .el-dialog"
            });

            try {
                


                let backhaulPath = []
                this.fileList2.forEach(ele=>{
                    backhaulPath.push(ele.path)
                })
                this.pdfList2.forEach(ele=>{
                    backhaulPath.push(ele.path)
                })
                this.PB.imgPath2 = backhaulPath

                let param = {
                     id:this.clickRow.id,
                    backhaulPath:this.PB.imgPath2.join(),
                    bankName:this.PB.bankName,
                    bankAccount:this.PB.bankAccount,
                    bankNum:this.PB.bankNum,

                }
                if(this.clickRow.bankName&&this.clickRow.accountName&& this.clickRow.bankNum){ //弹窗没回显 就传原来的
                    param.bankName = this.clickRow.bankName
                    param.bankAccount = this.clickRow.accountName
                    param.bankNum = this.clickRow.bankNum
                }




                if(this.clickRow.type==2){
                    let _imgPath = []
                    this.fileList.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.pdfList.forEach(ele=>{
                        _imgPath.push(ele.path)
                    })
                    this.PB.imgPath = _imgPath
                    param.imgPath=this.PB.imgPath.join()
                }

                await this.$refs.form.validate();

                
                
                const res = await this.$axios({
                    url: "/api/channel/updatePostbackTreaty",
                    method: "post",
                    data: param
                })

                if (res.code === 2000) {
                    if(this.clickRow.stampState==0){
                        this.$alert('请提醒财务进行协议盖章，盖章后协议方可生效', '保存成功', {
                            confirmButtonText: '确定',
                            callback: () => {
                                loadingRef && loadingRef.close();
                                this.fetchData()
                                this.handleClose();
                            }
                        });
                    }else{
                        this.$message({
                            message: "保存成功",
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.fetchData()
                        this.handleClose();

                    }




                    
                    

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },
        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                id:'',
                imgPath:'',
                imgPath2:'',
                bankName:'',
                bankAccount:'',
                bankNum:'',
            };
            this.$refs.form.clearValidate();
        },
        async fetchTreatyList() {
            const res = await this.$axios({
                url: "/api/channel/queryTreatyAllList",
                method: "post",
            });
            if (res.code === 2000) {
                this.treatyList = res.data.treatyList || [];
            }else{
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning"
                });
            }
        },
        // 搜索对接员工
        async remoteMethod(query) {
            console.log(query);
            if (query !== "") {
                this.loading = true;
                const res = await this.$axios({
                    url: "/api/employee/queryEmployeeList",
                    method: "post",
                    data: {
                        searchName: query,
                    },
                });
                if (res.code === 2000) {
                    this.loading = false;
                    res.data.empList.map((item) => {
                        item.label = item.name + "--" +item.usercode+"--" + item.phone;
                        item.value = item.usercode;
                    });
                    this.empOptions = res.data.empList;
                }
            } else {
                this.empOptions = [];
            }
        },
        cancelEditEmpDiaTop(){
            if(this.showEditEmpDia){
                this.cancelEditEmpDia()
            }
        },
        cancelEditEmpDia(){
            this.editEmpInfo.sys = ''
             this.editEmpInfo.empid = ''
            this.showEditEmpDia = false
            setTimeout(() => {
                this.$refs.editEmpInfo.clearValidate();
            }, 20);
        },

        handleEditEmpDia(){
             if (this.multipleSelection.length == 0) {
                this.$message({
                    message: "请选择需要修改的数据",
                    type: "warning",
                });
                return;
            }

            this.showEditEmpDia = true
        },
         handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async seveEditEmpDia() {
            let loadingRef;
            try {
                await this.$refs.editEmpInfo.validate();
                loadingRef = this.$loading({
                    target: ".editEmpDia .el-dialog"
                });

                let codearr = [];
                this.multipleSelection.forEach((item) => {
                    codearr.push(item.channelCode);
                });
                const res = await this.$axios({
                    url: "/api/common/channel/updateChannelConnEmp",
                    method: "post",
                    data: {
                        channelCodes: codearr.join(),
                        empCode: this.editEmpInfo.empid,
                        sysCode: this.editEmpInfo.sys,
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.cancelEditEmpDia()
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;
            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                loadingRef && loadingRef.close();
                console.warn(reason);
            }

        },

        // 详情
        handleDetail(item){
            this.clickRow = item
            this.fileList= []
            this.pdfList = []
            this.fileList2= []
            this.pdfList2 = []

            if(this.clickRow.bankName==''||this.clickRow.accountName==''||this.clickRow.bankNum==''){ //不完整弹窗回显 
                this.PB.bankName = this.clickRow.bankName
                this.PB.bankAccount = this.clickRow.accountName
                this.PB.bankNum = this.clickRow.bankNum
            }

            if (item.imgPath) {
                let imgPathArr = item.imgPath.split(",");
                imgPathArr.forEach((element) => {
                    const suffix = element.substring(element.lastIndexOf('.') + 1)
                    if(suffix=='pdf'){
                            this.pdfList.unshift({
                            name: "",
                            url: this.$baseURL + "/" + element,
                            path: element,
                        });
                        
                    }else{
                        this.fileList.unshift({
                            name: "",
                            url: this.$baseURL + "/" + element,
                            path: element,
                        });
                    }
                });
            }
            this.viewIsReady = true
            console.log(this.pdfList);
            console.log(this.fileList);


            // 渠道详情

            // this.$router.push({
			// 	path: `/customer/channelPhoneList/Detail`,
			// 	query: {id: data.id,channelCode:data.channelCode}
			// });

            // 渠道新增 编辑

            // this.$router.push({
			// 	path: `/customer/channelPhoneList/add`,
			// 	query: {id: data.id}
			// });

            // 在线签署渠道协议列表
            // this.$router.push({
			// 	path: `/customer/channelLineList`,
			// 	query: {id: data.id}
			// });

        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("请先确定该渠道在各业务系统中是否关联客户，删除后会同步删除业务系统中的渠道，您确定要删除么？", "删除渠道提示信息")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/deleteChannel",
                    method: "post",
                    data: {
                        id: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            this.search_value = "";
            this.search_name = "";
            this.search_type = 1;
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                // url: "/api/channel/queryChannelPhoneList",
                url: "/api/channel/waitPostbackAgreement",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    searchSysCode:this.sysCode||"AYM",
                    searchType:this.search_type,
                    searchValue: this.search_value,
                    treatyId: this.search_name,
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.waitPostbackList || [];
                this.total = res.data.count || 0;
            }else{
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning"
                });
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
    .empidselect{
        width: 340px;
    }
    .filefrom  /deep/ .el-icon-delete{
        background-color: rgba(12, 11, 11, 0.3);
        color: #fff;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        border-top-right-radius: 6px;
    }
        // 上传图片
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader /deep/ .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
  .avatar {
    width: 80px;
    height: 80px;
    display: block;
  }
  .flex{
    display: flex;
}
  .imgItem {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
}
.imgItem .img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    border-top-right-radius: 10px;
}


.imgItem .del{
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0px;
    color: #ccc;
    top: 2px;
    cursor: pointer;
}
.imgItem .del:hover{
    color: red;
}
.clomunform /deep/ .el-form-item__content {
    display: flex;
    flex-direction: column;
}

// 
</style>
<style>
.filefrom .el-form-item__content {
    flex-wrap: wrap;
}
.filefrom .el-form-item__content .el-upload__tip{
    width: 100%;
    flex-shrink: 0;
    margin-top: 0;
    color: #D0021B;
}

</style>